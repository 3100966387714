import awsmobile from "../aws-exports";
import { HttpApi } from "./http-api";

export namespace CameraControlApi {
    /**
     * {@link CameraControlApi.capture} 関数のオプション定義
     */
    export interface CaptureOptions {
        /**
         * 中止シグナル
         */
        readonly signal?: AbortSignal | undefined;
    }

    /**
     * カメラ操作の操作内容
     */
    export type Operation =
        | "move=up"
        | "move=right"
        | "move=down"
        | "move=left"
        | "move=home"
        | "zoom=wide"
        | "zoom=tele";

    /**
     * {@link CameraControlApi.control} 関数のオプション定義
     */
    export type ControlOptions =
        | ControlOptions.Capture
        | ControlOptions.NotCapture;

    export namespace ControlOptions {
        /**
         * {@link CameraControlApi.control} 関数のオプション定義
         *
         * これは、ふたつのオーバーロードの共通部分です。
         */
        export interface Common {
            /**
             * 中止シグナル
             */
            readonly signal?: AbortSignal | undefined;
        }

        /**
         * {@link CameraControlApi.control} 関数のオプション定義
         *
         * これは、最新画像を取得するオーバーロードです。
         */
        export interface Capture extends Common {
            /**
             * 最新画像を取得するフラグ
             *
             * @default false
             */
            readonly retrieveImage: true;
        }

        /**
         * {@link CameraControlApi.control} 関数のオプション定義
         *
         * これは、最新画像を取得しないオーバーロードです。
         */
        export interface NotCapture extends Common {
            /**
             * 最新画像を取得するフラグ
             *
             * @default false
             */
            readonly retrieveImage?: false | undefined;
        }
    }
}

/**
 * カメラ制御 API
 */
export const CameraControlApi = new (class CameraControlApi extends HttpApi {
    /**
     * カメラ画像を更新します。
     *
     * S3 バケットの最新画像を更新し、キャプチャした画像を返します。
     *
     * @param nodeId ノード ID
     * @param options オプション
     * @returns キャプチャしたカメラ画像
     */
    async capture(
        nodeId: string,
        options?: CameraControlApi.CaptureOptions,
    ): Promise<Blob> {
        const response = await this.request(
            `/cameras/${encodeURIComponent(nodeId)}`,
            {
                body: JSON.stringify({ action: "capture" }),
                headers: {
                    Accept: "image/jpeg",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                method: "POST",
                signal: options?.signal,
            },
        );

        if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`);
        }

        const blob = await response.blob();
        if (!blob.type.startsWith("image/")) {
            throw new Error(`Expected an image blob, but got ${blob.type}`);
        }

        return blob;
    }

    /**
     * カメラを操作します。
     *
     * S3 バケットの最新画像を更新し、キャプチャした画像を返します。
     *
     * @param nodeId ノード ID
     * @param operation 操作内容
     * @param options オプション
     * @returns キャプチャしたカメラ画像
     */
    async control(
        nodeId: string,
        operation: CameraControlApi.Operation,
        options: CameraControlApi.ControlOptions.Capture,
    ): Promise<Blob>;

    /**
     * カメラを操作します。
     *
     * S3 バケットの最新画像を更新しますが、キャプチャした画像を返しません。
     *
     * @param nodeId ノード ID
     * @param operation 操作内容
     * @param options オプション
     */
    async control(
        nodeId: string,
        operation: CameraControlApi.Operation,
        options?: CameraControlApi.ControlOptions.NotCapture | undefined,
    ): Promise<void>;

    // 実装
    async control(
        nodeId: string,
        operation: CameraControlApi.Operation,
        options?: CameraControlApi.ControlOptions,
    ): Promise<Blob | void> {
        const retrieveImage = options?.retrieveImage ?? false;
        const response = await this.request(
            `/cameras/${encodeURIComponent(nodeId)}`,
            {
                body: JSON.stringify({ action: "operate", operation }),
                headers: {
                    Accept: retrieveImage ? "image/jpeg" : "none",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                method: "POST",
                signal: options?.signal,
            },
        );

        if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`);
        }
        if (!retrieveImage) {
            return undefined;
        }

        const blob = await response.blob();
        if (!blob.type.startsWith("image/")) {
            throw new Error(`Expected an image blob, but got ${blob.type}`);
        }

        return blob;
    }
})(awsmobile.serecaCameraControlApiEndpoint);
